import React, { FC } from 'react';
import { PageProps } from 'gatsby';
import { Layout } from '../components/Layout/Layout';

import { SEO } from '../components/SEO/SEO';

import '../styles/work.scss';

const Work: FC<PageProps> = ({ location }) => {
  return (
    <Layout>
      <SEO
        pagetitle="WORK"
        pagedesc="ITエンジニアのブログです。"
        pagepath={location.pathname}
      />
      <div className="work">
        <article className="work__article">
          <h1 className="work__title">活動実績</h1>
          <div className="work__card">
            <div className="work__card-header">
              <p className="work__card-date">
                <span className="work__card-now">NOW</span> 2020年6月
              </p>
              <h2 className="work__card-title">
                ビーコンによる人の位置測定システム開発
              </h2>
            </div>
            <div className="work__card-body">
              <h3>WHAT TO DO</h3>
              <p className="work__card-brief">
                バックエンドエンジニアとしてPoC参画中。
              </p>
              <h3>POINT</h3>
              <p className="work__card-point">
                現在、システムアーキテクチャを検討しています。
                使用技術：typescript, go, terraform, raspberry pi, react
              </p>
            </div>
          </div>
          <div className="work__card">
            <div className="work__card-header">
              <p className="work__card-date">2020年05月</p>
              <h2 className="work__card-title">商品管理システムのリプレース</h2>
            </div>
            <div className="work__card-body">
              <h3>WHAT TO DO</h3>
              <p className="work__card-brief">
                バックエンドエンジニアとして参画。
                <br />
                課題は、複雑な機能を過剰に実装していて逆にどう使えばいいのか不明になっている点でした。
                <br />
                エンジニア目線から提案しつつ、シンプルに作り直しました。
                <br />
              </p>
              <h3>POINT</h3>
              <p className="work__card-point">
                コアとなる機能を前システムから分析し、顧客業務を考えつつエンジニア主導で開発を先行しました。
                <br />
                理由は、スピード感が求められていたためです。
                <br />
                その後、顧客ヒアリングを進め徐々に機能を盛り込みます。
              </p>
            </div>
          </div>
          <div className="work__card">
            <div className="work__card-header">
              <p className="work__card-date">2020年01月</p>
              <h2 className="work__card-title">
                ECサイトの業者見積精査とアドバイス
              </h2>
            </div>
            <div className="work__card-body">
              <h3>WHAT TO DO</h3>
              <p className="work__card-brief">
                新たにECサイト立ち上げに際し、業者に見積もり依頼をされたお客様でした。
                <br />
                しかし、専門用語が多く実現したいことができるのか、見積もりが正しいのかお困りでした。
                <br />
                用語の解説や機能の要不要の判断をご一緒に検討しました。
              </p>
              <h3>POINT</h3>
              <p className="work__card-point">
                ECサイトをどう活用するのかがすこし曖昧でしたので、ビジネスアイディアの整理もお手伝いしています。
              </p>
            </div>
          </div>
          <div className="work__card">
            <div className="work__card-header">
              <p className="work__card-date">2019年09月</p>
              <h2 className="work__card-title">
                既存システムのリバースエンジニアリング
              </h2>
            </div>
            <div className="work__card-body">
              <h3>WHAT TO DO</h3>
              <p className="work__card-brief">
                外注しているシステムを内製化したいとのことでした。
                <br />
                そこで、外注システムの画面を分析しDB設計を復元しました。
                <br />
              </p>
              <h3>POINT</h3>
              <p className="work__card-point">
                システムは「インプット＝＞処理＝＞アウトプット」の流れになります。
                <br />
                アウトプットは多岐に渡ることが多いのですが、その源泉であるインプットは共通化されています。
                <br />
                そこに着目して入力画面より分析を始めました。
              </p>
            </div>
          </div>
          <div className="work__card">
            <div className="work__card-header">
              <p className="work__card-date">2019月06月</p>
              <h2 className="work__card-title">
                WEBサイトのクローリング(スクレイピング)
              </h2>
            </div>
            <div className="work__card-body">
              <h3>WHAT TO DO</h3>
              <p className="work__card-brief">
                人海戦術では膨大な時間を要する作業を自動化しました。
              </p>
              <h3>POINT</h3>
              <p className="work__card-point">
                クローリングはサイトによっては制限・禁止されています。
                <br />
                顧客と協議のうえ、アクセス回数と実行タイミングを制御して実現しました。
              </p>
            </div>
          </div>
          <div className="work__card">
            <div className="work__card-header">
              <p className="work__card-date">2019年05月</p>
              <h2 className="work__card-title">請求書PDF出力システムの開発</h2>
            </div>
            <div className="work__card-body">
              <h3>WHAT TO DO</h3>
              <p className="work__card-brief">
                課題は、得意先に差し出す請求書をエクセルで一枚づつ作っており、月次工数の高さでした。
                システム上で集計・請求書PDFの出力を行うシステムを開発しました。
              </p>
              <h3>POINT</h3>
              <p className="work__card-point">
                請求金額の計算が煩雑になっていたため、業務整理を行いつつ、必要なデータを別システムよりAPI連携するようにしました。
              </p>
            </div>
          </div>
          <div className="work__card">
            <div className="work__card-header">
              <p className="work__card-date">2019年01月</p>
              <h2 className="work__card-title">
                新サービスの実現方法アドバイス
              </h2>
            </div>
            <div className="work__card-body">
              <h3>WHAT TO DO</h3>
              <p className="work__card-brief">
                お客様は、ウェブを活用した新たなサービスを作りたいと考えていらっしゃいました。
                <br />
                しかし、具体的なシステムアーキテクチャでお困りでしたので、方針をご提案しました。
              </p>
              <h3>POINT</h3>
              <p className="work__card-point">
                新たなサービスをどうユーザが利用するのかはよく考えられていたのですが、そもそものデータをどう蓄積するかが曖昧でした。
                <br />
                データの保存スキーマと料金体系についてご提案いたしました。
              </p>
            </div>
          </div>
        </article>
      </div>
    </Layout>
  );
};

export default Work;
